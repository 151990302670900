/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
import React from "react";
import "./CreatingCustomersView.scss";
import "../../scss/index.scss";
import clsx from "clsx";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
} from "@mui/material";
import { useDispatch } from "react-redux";
import DataGrid from "../DataGrid/DataGrid";
import "dayjs/locale/nl";
import SearchAutocomplete from "../SearchAutocomplete";
import useDebounce from "../../utils/helpers/debounceHook";
import { contractApi } from "../../utils/api";
import { getNameFromArrayId } from "../../utils/helpers/entityNameById";
import Multiselect from "../DataGrid/Multiselect";
import CustomersDialog from "./CustomersDialog";
import DialogCancel from "./CancelDialog";
import ModalActivate from "../../pages/Access/ModalActivate";
import ModalCancel from "../../pages/Access/ModalDisable";
import { enableUser, removeUser } from "../../redux/actions/accessActions";
import ModalDisable from "../../pages/Access/ModalDisable";
import { enableCustomer } from "../../redux/actions/creatingCustomersActions";

function CreatingCustomers({
  permissions,
  entitiesTypes,
  addCustomer,
  updateData,
  removeData,
  data,
  ...props
}) {
  const initialUserValues = {
    full_name: "",
    email: "",
    role: [""],
    number: "",
  };

  const initiaReverseValues = {
    full_name: "ФИО",
    email: "Email",
    role: "Роль",
    number: "Номер договора",
  };

  const role = [
    { value: "8000", label: "Родитель" },
    { value: "11111", label: "Ребенок" },
  ];

  // const bedRequest = useSelector((state) => state.schedule_view.badRequest);

  const [newUser, setNewUser] = React.useState(initialUserValues);
  const [customers, setCustomers] = React.useState([]);
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [contractInfo, setContractInfo] = React.useState(null);
  const [details, setRowDetails] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [confirmDisable, setConfirmDisable] = React.useState(null);
  const [openAddDialog, setOpenAddDialog] = React.useState(false);
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);

  const initialDisabledUser = {
    last_activity: "",
    access_id: [],
    email: "",
    short_name: "",
    location: [],
    status: "",
  };

  const [isButtonShow, setIsButtonShow] = React.useState(false);
  const [infoAboutUser, setInfoAboutUser] = React.useState(initialDisabledUser);
  const [isActivateModalOpen, setActivateModalOpen] = React.useState(false);
  const [isDisableModalOpen, setDisableModalOpen] = React.useState(false);

  const dispatch = useDispatch();

  const clearAllData = () => {
    setNewUser(initialUserValues);
    setInputValue("");
    setOptions([]);
    // setContractInfo(null);
    setCustomers([]);
    setValue(null);
    setErrorMessage("");
  };

  const clearWithoutCustomersData = () => {
    setNewUser(initialUserValues);
    setInputValue("");
    setOptions([]);
    // setContractInfo(null);
    setValue(null);
    setErrorMessage("");
  };

  const openDialogCallbackParent = () => {
    setOpenAddDialog(false);
    setCustomers([]);
  };

  const handleClose = () => {
    setOpenCancelDialog(false);
  };

  // Dialog

  const [openDialog, setOpenDialog] = React.useState(false);
  const closeDialog = () => {
    setContractInfo(null);
    setConfirmDisable(false);
    setOpenDialog(false);
  };

  // Dialog end

  // autosearchActions
  const onChangeHandler = (_event, newValue) => {
    if (!newValue) {
      setNewUser(initialUserValues);
      setInputValue("");
      setOptions([]);
      setValue(null);
      return;
    }
    setValue(newValue);
    setNewUser({
      ...newUser,
      number: newValue?.number || "",
    });
    if (newUser.role === "8000") {
      setNewUser({
        ...newUser,
        full_name: newValue?.full_name || "",
      });
    }
    if (newUser.role === "11111") {
      setNewUser({
        ...newUser,
        full_name: newValue?.kid_name || "",
      });
    }
    if (newValue) {
      setContractInfo(newValue);
      console.log("contractI", contractInfo);
    }
  };

  const onInputHandler = (_event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const debounce = useDebounce(inputValue, 1000);

  const search = (val) => {
    setSearchLoading(true);
    contractApi.get(val).then((response) => {
      const searchResult = Object.entries(response.data).map(
        ([
          key,
          { contract_id, full_name, number, status, course, kid_name },
        ]) => ({
          contract_id,
          full_name,
          number,
          status,
          course,
          kid_name,
        })
      );
      setSearchLoading(false);
      setOptions([...searchResult]);
    });
  };

  React.useEffect(() => {
    if (debounce) {
      search(debounce);
    } else {
      setOptions([]);
    }
  }, [debounce]);

  // autosearchActions end

  const handleUserInputs = (e) => {
    if (e.target.name === "role") {
      setNewUser({
        ...newUser,
        [e.target.name]: [e.target.value],
        full_name:
          e.target.value === "11111"
            ? contractInfo?.kid_name
            : contractInfo?.full_name,
      });
    } else {
      setNewUser({
        ...newUser,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.value) {
      setErrorMessage("");
    }
  };

  const handleUserAddCheck = () => {
    const result = Object.keys(newUser).filter((item) => newUser[item] === "");
    if (result.length !== 0) {
      const fields = result.map((item) => initiaReverseValues[item]);
      setErrorMessage(
        `необходимо заполнить все поля: ${fields} ${
          newUser.role.includes("") ? ",Роль" : ""
        } `
      );
    }
  };

  const handleUserAdd = () => {
    if (
      newUser.email === "" ||
      newUser.full_name === "" ||
      newUser.role.includes("")
    ) {
      handleUserAddCheck();
      return;
    }
    setOpenAddDialog(true);
    let equal = false;
    const user = [
      {
        ...newUser,
        contract_id: contractInfo?.contract_id,
      },
    ];
    // eslint-disable-next-line array-callback-return
    customers.map((item) => {
      if (
        Object.keys({ ...item, ...user[0] }).every(
          (key) => item[key] === user[0][key]
        )
      ) {
        equal = true;
      }
    });
    if (equal) {
      return;
    }
    setCustomers([...customers, ...user]);
    clearWithoutCustomersData();
  };

  const handleUpdInputs = (e) => {
    setRowDetails({
      ...details,
      [e.target.name]: e.target.value,
    });
  };

  const submitUpdUser = () => {
    if (details.contract.length === 0) {
      setConfirmDisable(true);
      return;
    }
    updateData({ customers: details });
    setOpenDialog(false);
  };

  // editDialoog Actions
  const addContract = () => {
    if (!contractInfo || !details) {
      return;
    }
    if (
      details?.contract.some((item) =>
        Object.values(item).includes(contractInfo.number)
      )
    ) {
      return;
    }
    if (contractInfo && details) {
      setRowDetails({
        ...details,
        ...details.contract.push({
          contract_id: contractInfo.contract_id,
          number: contractInfo.number,
        }),
      });
    }
  };

  const deleteContract = (event) => {
    const number = event.target.previousSibling.textContent;
    if (number) {
      setRowDetails({
        ...details,
        contract: details.contract.filter(
          (item) => !item.number.includes(number)
        ),
      });
    }
  };
  // editDialoog Actions end

  const submitDisable = () => {
    removeData({ customers: details });
  };

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const handleRowClick = (row) => {
    setIsButtonShow(true);
    setInfoAboutUser(row.row);
  };

  const handleRowDoubleClick = (row) => {
    clearAllData();
    setOpenDialog(true);
    setRowDetails(row.row);
  };

  // Disable and activate user actions
  const handleOpenDisableDialog = () => {
    setDisableModalOpen(true);
  };
  const handleCloseDisableDialog = () => {
    setDisableModalOpen(false);
  };

  const submitDisableUser = () => {
    removeData({ customers: infoAboutUser });
  };

  const handleCloseActivateModal = () => {
    setActivateModalOpen(false);
  };
  const activateUser = () => {
    dispatch(enableCustomer(infoAboutUser.id_user));
  };

  const handleActivateUser = () => {
    setActivateModalOpen(true);
  };
  // Disable and activate user actions end

  const convertToString = (roleNumber) => {
    if (roleNumber == "11111") {
      return "Ребенок";
    }
    if (roleNumber == "8000") {
      return "Родитель";
    }
  };

  const columns = [
    {
      field: "id_user",
      headerName: "id",
      hide: true,
    },
    {
      field: "full_name",
      headerName: "ФИО",
      flex: 0.2,
    },
    {
      field: "email",
      headerName: "email",
      flex: 0.1,
    },
    {
      field: "role",
      headerName: "Роль",
      flex: 0.1,
      editable: true,
      valueFormatter: (params) => {
        if (params.value) {
          const accessArray = getNameFromArrayId(params.value, role);
          return accessArray.join(", ");
        }
        return "";
      },
      renderEditCell: (params) => {
        if (params.row.status === "active") {
          return <Multiselect options={role} {...params} />;
        }
      },
    },
    {
      field: "last_activity",
      headerName: "Последняя дата входа",
      flex: 0.2,
      editable: false,
    },
    {
      field: "number",
      headerName: "№ договора",
      flex: 0.2,
      editable: false,
      valueGetter: (params) => params.value.join(","),
    },
    {
      field: "status",
      headerName: "Статус",
      flex: 0.1,
      editable: false,
      headerClassName: "super-app-theme--header",
      // eslint-disable-next-line consistent-return
      cellClassName: (params) => {
        const userActivity = params.row.status;
        if (userActivity)
          return clsx("super-app", {
            positive: userActivity === "active",
            negative: userActivity !== "active",
          });
      },
    },
  ];

  return (
    <>
      <div>
        {!openDialog && (
          <>
            {" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start-end",
                flexWrap: "wrap",
                margin: "10px 25px 10px 25px",
                alignItems: "center",
              }}
              component="div"
            >
              <SearchAutocomplete
                label="Номер договора / Фамилия / Телефон"
                value={value}
                setValue={onChangeHandler}
                inputValue={inputValue}
                setInputValue={onInputHandler}
                options={options}
                getOptionLabel={(option) =>
                  `${option.number} ${option.full_name}`
                }
                loading={searchLoading}
                loadingText="Поиск..."
                blurOnSelect
                clearOnBlur
                size="small"
                sx={{
                  width: "fit-content",
                  minWidth: "20vw",
                  margin: "5px",
                }}
              />

              <Button
                variant="contained"
                aria-label="add to shopping cart"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                style={{
                  marginLeft: "1%",
                }}
                onClick={handleUserAdd}
              >
                Добавить пользователя
              </Button>
            </Box>
            <Box
              style={
                inputValue
                  ? { display: "block", opacity: "1" }
                  : { display: "none", opacity: "0" }
              }
              className="user__data"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start-end",
                flexWrap: "wrap",
                margin: "10px 25px 10px 25px",
              }}
              component="div"
            >
              <FormControl>
                <TextField
                  name="email"
                  type="email"
                  size="small"
                  id="outlined-email"
                  value={newUser.email}
                  label="email"
                  // eslint-disable-next-line jsx-a11y/aria-proptypes
                  aria-sort="label"
                  onChange={handleUserInputs}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: "fit-content",
                    minWidth: "150px",
                    margin: "5px",
                  }}
                />
              </FormControl>
              <TextField
                fullWidth
                name="role"
                select
                type="text"
                size="small"
                id="outlined-role"
                value={newUser.role}
                label="Роль"
                // eslint-disable-next-line jsx-a11y/aria-proptypes
                aria-sort="label"
                onChange={handleUserInputs}
                sx={{
                  width: "fit-content",
                  minWidth: "20vw",
                  margin: "5px",
                }}
              >
                <MenuItem value="8000">Родитель</MenuItem>
                <MenuItem value="11111">Ребенок</MenuItem>
                <MenuItem value="" />
              </TextField>
              <FormControl>
                <TextField
                  fullWidth
                  name="full_name"
                  type="text"
                  size="small"
                  id="outlined-full_name"
                  value={newUser.full_name}
                  label="ФИО"
                  // eslint-disable-next-line jsx-a11y/aria-proptypes
                  aria-sort="label"
                  onChange={handleUserInputs}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: "fit-content",
                    minWidth: "20vw",
                    margin: "5px",
                  }}
                />
              </FormControl>
            </Box>
          </>
        )}
        {isButtonShow && infoAboutUser.status === "active" && (
          <>
            <Button
              variant="contained"
              type="button"
              onClick={handleOpenDisableDialog}
              size="small"
              sx={{
                margin: "9px",
                height: "37px",
              }}
            >
              Деактивировать пользователя
            </Button>
            <ModalDisable
              open={isDisableModalOpen}
              handleClose={handleCloseDisableDialog}
              disableConfirmed={submitDisableUser}
            />
          </>
        )}{" "}
        {isButtonShow && infoAboutUser.status === "disabled" && (
          <>
            <Button
              variant="contained"
              type="button"
              onClick={handleActivateUser}
              size="small"
              sx={{
                margin: "9px",
                height: "37px",
              }}
            >
              Активировать пользователя
            </Button>
            <ModalActivate
              open={isActivateModalOpen}
              handleClose={handleCloseActivateModal}
              activateConfirmed={activateUser}
            />
          </>
        )}
        <CustomersDialog
          customers={customers}
          open={openAddDialog}
          addCustomer={addCustomer}
          openDialogCallback={openDialogCallbackParent}
        />
      </div>
      <Box
        sx={{
          margin: "0 30px",
          color: "red",
          height: "20px",
          display: errorMessage ? "block" : "none",
        }}
      >
        {errorMessage}
      </Box>
      <div className="table__wrapper">
        {/* {bedRequest ? (
          <div
            className="error"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "100px",
            }}
          >
            {" "}
            Вы отправили неверные данные. Попробуйте еще раз.{" "}
          </div>
        ) : null} */}
        <Box
          sx={{
            "& .super-app-theme--cell": {
              backgroundColor: "rgba(224, 183, 60, 0.55)",
              color: "#1a3e72",
              fontWeight: "600",
              border: "1px solid #000000",
            },
            "& .super-app.negative": {
              backgroundColor: "#fa8e64",
              color: "#1a3e72",
              fontWeight: "600",
              border: "1px solid #000000",
            },
            "& .super-app.positive": {
              backgroundColor: "rgba(140, 255, 111, 0.747)",
              color: "#1a3e72",
              fontWeight: "600",
              border: "1px solid #000000",
            },
          }}
        >
          <DataGrid
            onRowDoubleClick={(event, row) => handleRowDoubleClick(event, row)}
            onCellClick={(event, row) => handleRowClick(event, row)}
            columns={columns}
            getRowId={(row) => row.id_user}
            entityName="customers"
            dataGridName="create_cistomers_personal_area"
            permissions={permissions}
            data={data}
            {...props}
          />
        </Box>
      </div>

      <Dialog
        className="customers__dialog"
        open={openDialog}
        onClose={closeDialog}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        PaperProps={{
          sx: {
            width: "60%",
          },
        }}
      >
        <DialogTitle>
          Редактировать {details?.full_name}
          <div
            style={{ float: "right", cursor: "pointer" }}
            title="зыкрыть"
            onClick={closeDialog}
          >
            <CloseIcon />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {confirmDisable ? (
              <div className="cofirm__disable_container">
                У пользователя нет открытых договоров. Его статут будет
                переведен в &quot;disabled&quot;
              </div>
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start-end",
                    flexWrap: "wrap",
                    margin: "10px 5px",
                  }}
                  component="div"
                >
                  <TextField
                    className="dialog__input"
                    name="full_name"
                    type="text"
                    size="small"
                    id="outlined-email"
                    value={details?.full_name || ""}
                    label="ФИО"
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-sort="label"
                    onChange={handleUpdInputs}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    className="dialog__input"
                    name="email"
                    type="email"
                    size="small"
                    id="outlined-email"
                    value={details?.email || ""}
                    label="Email"
                    // eslint-disable-next-line jsx-a11y/aria-proptypes
                    aria-sort="label"
                    onChange={handleUpdInputs}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <TextField
                    className="dialog__input"
                    disabled
                    name="role"
                    type="text"
                    size="small"
                    id="outlined-role"
                    defaultValue={convertToString(details?.role) || ""}
                    label="Роль"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />

                  <div className="dialog__input search_field">
                    <SearchAutocomplete
                      fullWidth
                      label="Поиск по договору"
                      value={value}
                      setValue={onChangeHandler}
                      inputValue={inputValue}
                      setInputValue={onInputHandler}
                      options={options}
                      getOptionLabel={(option) =>
                        `${option.number} ${option.full_name}`
                      }
                      loading={searchLoading}
                      loadingText="Поиск..."
                      blurOnSelect
                      clearOnBlur
                      size="small"
                    />
                    <AddIcon
                      style={{ cursor: "pointer" }}
                      color="success"
                      fontSize="large"
                      onClick={addContract}
                    />
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start-end",
                    flexWrap: "wrap",
                    margin: "10px 5px",
                  }}
                  component="div"
                >
                  <div className="contracts__info_area">
                    <div className="contracts__container">
                      <div style={{ color: "#000", fontWeight: "600" }}>
                        Договора клиента:
                      </div>
                      {details?.contract?.map((item) => (
                        <div className="contracts__item">
                          <div className="contracts_number">{item.number}</div>
                          <div
                            className="contracts_action"
                            onClick={(e) => deleteContract(e)}
                          >
                            {" "}
                            удалить &mdash;
                          </div>
                        </div>
                      ))}
                    </div>
                    {/* <div className="contracts__disabled">
                      <Button
                        onClick={handleOpenCancelDialog}
                        variant="contained"
                      >
                        Деактивировать пользователя
                      </Button>
                    </div> */}
                  </div>
                </Box>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className="confirm__buttons">
            <Button onClick={closeDialog} variant="contained" color="grey">
              Отменить
            </Button>
            {confirmDisable ? (
              <Button onClick={submitDisable} variant="contained">
                OK
              </Button>
            ) : (
              <Button onClick={submitUpdUser} variant="contained">
                Отправить
              </Button>
            )}
          </div>
        </DialogActions>
      </Dialog>
      {/* <DialogCancel
        open={openCancelDialog}
        handleClose={handleClose}
        disableUser={submitDisableUser}
      /> */}
    </>
  );
}

export default CreatingCustomers;
