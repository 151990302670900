import { accessApi } from "../../utils/api";
import notification from "../../utils/notification/notification";
import {
  getUniqueEntities,
  getUniqueEntitiesHidden,
} from "./entitiesTypesActions";

export const getAccess = (data) => ({
  type: "GET_ACCESS",
  payload: data,
  loading: false,
});

export const loadingAccess = () => ({
  type: "LOADING_ACCESS",
  loading: true,
});

export const setUpdatedAccess = (data) => ({
  type: "UPDATE_ACCESS",
  payload: data,
  loading: false,
});

export const addUser = (data) => ({
  type: "ADD_USER",
  payload: data,
  loading: false,
});

export const disableUser = (id) => ({
  type: "DISABLE_USER",
  payload: id,
  loading: false,
});

export const activateUser = (data) => ({
  type: "ACTIVATE_USER",
  payload: data,
  loading: false,
});

export const clearAccess = () => ({
  type: "CLEAR_ACCESS",
});

export const fetchAccess = () => (dispatch) => {
  dispatch(loadingAccess());
  accessApi.get().then(({ data }) => dispatch(getAccess(data)));
};

export const updateAccess = (obj) => (dispatch) => {
  dispatch(loadingAccess());
  return accessApi.post(obj).then(({ data }) => {
    notification.success("Данные о пользователе обновлены");
    dispatch(setUpdatedAccess(data));
  });
};

export const addNewUser = (obj) => (dispatch) => {
  dispatch(loadingAccess());
  return accessApi.add(obj).then(({ data }) => {
    notification.success("Пользователь добавлен");
    dispatch(addUser(data));
    dispatch(getUniqueEntities());
  });
};

export const removeUser = (Obj) => (dispatch) => {
  dispatch(loadingAccess());
  return accessApi.disabled(Obj).then(({ data }) => {
    notification.success('Пользователю назначен статус "disabled"');
    dispatch(disableUser(data));
    // вызов функции для обновления списка пользователей в приложении
    dispatch(getUniqueEntitiesHidden());
  });
};

export const enableUser = (Obj) => (dispatch) => {
  dispatch(loadingAccess());
  return accessApi.activate(Obj).then(({ data }) => {
    notification.success('Пользователю назначен статус "active"');
    dispatch(disableUser(data));
    // вызов функции для обновления списка пользователей в приложении
    dispatch(getUniqueEntitiesHidden());
  });
};

// export const removeUser = (idObj) => (dispatch) => {
// 	console.log("removeUser", idObj);
// 	dispatch(loadingAccess());
// 	return accessApi.disabled(idObj).then(() => {
// 		notification.success("Пользователь не активен");
// 		dispatch(disableUser(idObj));
// 	});
// };
