/* eslint-disable no-restricted-syntax */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Select,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ModalDisable from "./ModalDisable";
import activeUsersSelection from "../../utils/helpers/activeUserSelection";

const ModalTransfer = ({
  open,
  handleClose,
  disableUser,
  userDebts,
  users,
  active_users,
}) => {
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const initialInputsState = {
    appointments: "",
    leads: "",
    tasks: "",
  };
  const [inputs, setInputs] = useState(initialInputsState);

  const handleInputsChange = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const isDeactivateButtonDisabled = () => {
    if (userDebts.appointments !== "0" && inputs.appointments === "")
      return true;
    if (userDebts.leads !== "0" && inputs.leads === "") return true;
    if (userDebts.tasks !== "0" && inputs.tasks === "") return true;
    return false;
  };

  const handleOpenCancelModal = () => {
    setCancelModalOpen(true);
  };

  const handleCloseCancelModal = () => {
    setCancelModalOpen(false);
  };

  const onCloseHandler = () => {
    handleClose();
    setInputs(initialInputsState);
  };

  const confirmHandler = () => {
    handleOpenCancelModal();
  };

  const disableConfirmed = () => {
    disableUser(inputs);
    handleClose();
    setInputs(initialInputsState);
  };

  return (
    <div>
      <Dialog open={open} onClose={onCloseHandler}>
        <DialogTitle>Деактивация пользователя</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Перенесите назначения, лиды и задачи на другого пользователя. (иначе
            деактивация не доступна).
          </DialogContentText>
          <Box display="flex" alignItems="center" my={2}>
            <Typography style={{ marginRight: 16, width: "30%" }}>
              Назначения({userDebts.appointments})
            </Typography>
            <FormControl fullWidth>
              <Select
                disabled={userDebts.appointments === "0"}
                size="small"
                name="appointments"
                labelId="appointments-label"
                value={inputs.appointments}
                onChange={(e) => handleInputsChange(e)}
              >
                {activeUsersSelection(users, active_users)}
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" my={2}>
            <Typography style={{ marginRight: 16, width: "30%" }}>
              Лиды({userDebts.leads})
            </Typography>
            <FormControl fullWidth>
              <Select
                disabled={userDebts.leads === "0"}
                size="small"
                labelId="leads-label"
                name="leads"
                value={inputs.leads}
                onChange={(e) => handleInputsChange(e)}
              >
                {activeUsersSelection(users, active_users)}
              </Select>
            </FormControl>
          </Box>
          <Box display="flex" alignItems="center" my={2}>
            <Typography style={{ marginRight: 16, width: "30%" }}>
              Задачи({userDebts.tasks})
            </Typography>
            <FormControl fullWidth>
              <Select
                disabled={userDebts.tasks === "0"}
                size="small"
                labelId="tasks-label"
                name="tasks"
                value={inputs.tasks}
                onChange={(e) => handleInputsChange(e)}
              >
                {activeUsersSelection(users, active_users)}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseHandler}>Назад</Button>
          <Button
            onClick={confirmHandler}
            color="error"
            disabled={isDeactivateButtonDisabled()}
          >
            Деактивировать
          </Button>
        </DialogActions>
      </Dialog>
      <ModalDisable
        open={isCancelModalOpen}
        handleClose={handleCloseCancelModal}
        disableConfirmed={disableConfirmed}
      />
    </div>
  );
};

export default ModalTransfer;
