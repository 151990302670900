import React from "react";
import "./AttendanceReportsPage.scss";
import { Button } from "@mui/material";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";

const StudentInfoModal = ({ studentInfo, onClose, entitiesTypes }) => {
  const { teachers } = entitiesTypes;

  const getStatusTranslate = (status) => {
    switch (status) {
      case "new":
        return "запланировано";
      case "done":
        return "проведено";
      case "gap":
        return "пропущено";
      default:
        return null;
    }
  };

  if (!studentInfo) return null; // Do not render if no student info

  return (
    <div className="attendance__report_student_iformation_wrapper">
      <div className="attendance__report_student_iformation">
        <span
          style={{
            float: "right",
            cursor: "pointer",
            margin: "10px",
          }}
          onClick={() => {
            onClose();
          }}
        >
          X
        </span>
        <h2 style={{ padding: "0", margin: "0" }}>
          {findLabelByID(studentInfo.subject, entitiesTypes.subject)}
        </h2>

        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            // marginTop: "10px",
          }}
        >
          <tbody>
            <tr>
              <th>Ребенок</th>
              <td>
                <table style={{ borderCollapse: "collapse" }}>
                  <tbody>
                    <tr>
                      <th>ФИО</th>
                      <td>{studentInfo.targetStudent.name}</td>
                    </tr>
                    <tr>
                      <th>Отзыв</th>
                      <td>{studentInfo.targetStudent.review || ""}</td>
                    </tr>
                    <tr>
                      <th>Заметка преподавателя</th>
                      <td>{studentInfo.targetStudent.description || ""}</td>
                    </tr>
                    <tr>
                      <th>Заметка администрации</th>
                      <td>
                        {studentInfo.targetStudent.description_admin || ""}
                      </td>
                    </tr>
                    <tr>
                      <th>Был на занятии</th>
                      <td>
                        {studentInfo.targetStudent.isPresence ? "Да" : "Нет"}
                      </td>
                    </tr>
                    {studentInfo.targetStudent.attendance_status &&
                    studentInfo.targetStudent.attendance_date ? (
                      <tr>
                        <th>Отработка</th>
                        <td>
                          {getStatusTranslate(
                            studentInfo.targetStudent.attendance_status
                          )}
                          ({studentInfo.targetStudent.attendance_date})
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </td>
            </tr>

            <tr>
              <th>Преподаватель</th>
              <td>
                <table style={{ borderCollapse: "collapse" }}>
                  <tbody>
                    <tr>
                      <th>ФИО</th>
                      <td>{findLabelByID(studentInfo.teacher, teachers)}</td>
                    </tr>
                    <tr>
                      <th>Тема</th>
                      <td>{studentInfo.topic}</td>
                    </tr>
                    <tr>
                      <th>Дата</th>
                      <td>{studentInfo.date}</td>
                    </tr>
                    <tr>
                      <th>Д/З</th>
                      <td>{studentInfo.home_work}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
        <Button
          variant="contained"
          style={{
            float: "right",
            margin: 0,
            padding: "0 10px",
          }}
          onClick={() => {
            onClose();
          }}
        >
          закрыть
        </Button>
      </div>
    </div>
  );
};

export default React.memo(StudentInfoModal);
