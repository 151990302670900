import { appointmentsApi } from "../../utils/api";
import { addAppointments } from "../actions/appointmentsActions";

const initialState = {
  appointments: [],
  substitute: [],
  loading: false,
  history: [],
  redoStack: [],
  error: "",
};

const substitutionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TEACHERS_PLAN":
      return {
        ...state,
        substitute: action.payload.substitute,
        appointments: action.payload.appointments,
        config: action.payload.config,
        loading: action.loading,
      };
    case "LOADING_APPOINTMENTS":
      return {
        ...state,
        loading: action.loading,
      };
    case "ADD_APPOINTMENTS":
      return {
        ...state,
        appointments: [...state.appointments, action.payload],
        loading: action.loading,
        error: action.error,
        history: [...state.history, { type: "add", lesson: action.payload }],
        redoStack: [],
      };
    case "CANCEL_APPOINTMENTS":
      return {
        ...state,
        loading: action.loading,
      };
    case "UPDATE_APPOINTMENTS":
      return {
        ...state,
        appointments: state.appointments.map((item) => {
          // eslint-disable-next-line eqeqeq
          if (item.id == action.payload.id) {
            return { ...item, ...action.payload };
          }
          return item;
        }),
        loading: action.loading,
        history: [...state.history, { type: "edit", lesson: action.payload }],
        redoStack: [],
      };
    case "ADD_SUBSTITUTION":
      return {
        ...state,
        substitute: [...state.substitute, action.payload],
        loading: action.loading,
      };
    case "UPDATE_SUBSTITUTION":
      return {
        ...state,
        substitute: state.substitute.map((item) =>
          // eslint-disable-next-line eqeqeq
          item.id == action.payload.id ? action.payload : item
        ),
        loading: action.loading,
      };
    case "REMOVE_APPOINTMENTS":
      return {
        ...state,
        appointments: state.appointments.filter(
          (item) => item.id !== action.payload.appointment
        ),
        loading: action.loading,
        history: [
          ...state.history,
          {
            type: "remove",
            lessonId: action.payload,
            lesson: state.appointments.find(
              (appt) => appt.id === action.payload
            ),
          },
        ],
        redoStack: [],
      };
    case "REMOVE_SUBSTITUTION":
      return {
        ...state,
        appointments: state.appointments.map((item) => {
          if (item.id === action.payload.appointment) {
            item.is_substitute = "";
          }
          return item;
        }),
        loading: action.loading,
      };

    case "SEND_ORIGIN_LESSON":
      return {
        ...state,
        history: [...state.history, { type: "edit", lesson: action.payload }],
      };

    case "UNDO": {
      if (state.history.length === 0) return state;
      const lastAction = state.history[state.history.length - 1];
      const updatedHistory = state.history.slice(0, -1);

      let newAppointments;
      if (lastAction.type === "add") {
        addAppointments({ appointment: lastAction.lesson });
        newAppointments = state.appointments.filter(
          (appt) => appt.id !== lastAction.lesson.id
        );
      } else if (lastAction.type === "edit") {
        appointmentsApi.update({ appointment: lastAction.lesson });
        newAppointments = state.appointments.map((appt) =>
          appt.id === lastAction.lesson.id ? lastAction.lesson : appt
        );
      } else if (lastAction.type === "remove") {
        newAppointments = [...state.appointments, lastAction.lesson];
      }

      return {
        ...state,
        appointments: newAppointments,
        history: updatedHistory,
        redoStack: [...state.redoStack, lastAction],
      };
    }

    case "REDO": {
      if (state.redoStack.length === 0) return state;
      const lastUndoneAction = state.redoStack[state.redoStack.length - 1];
      const updatedRedoStack = state.redoStack.slice(0, -1);

      let redoAppointments;
      if (lastUndoneAction.type === "add") {
        redoAppointments = [...state.appointments, lastUndoneAction.lesson];
      } else if (lastUndoneAction.type === "edit") {
        redoAppointments = state.appointments.map((appt) =>
          appt.id === lastUndoneAction.lesson.id
            ? lastUndoneAction.lesson
            : appt
        );
      } else if (lastUndoneAction.type === "remove") {
        redoAppointments = state.appointments.filter(
          (appt) => appt.id !== lastUndoneAction.lessonId
        );
      }

      return {
        ...state,
        appointments: redoAppointments,
        history: [...state.history, lastUndoneAction],
        redoStack: updatedRedoStack,
      };
    }

    default:
      return state;
  }
};

export default substitutionsReducer;
