import { appointmentsApi } from "../../utils/api";
import notification from "../../utils/notification/notification";

export const getTeachersPlan = (data) => ({
  type: "GET_TEACHERS_PLAN",
  payload: data,
  loading: false,
});

export const loadingAppointments = () => ({
  type: "LOADING_APPOINTMENTS",
  loading: true,
});

export const addAppointments = (data) => ({
  type: "ADD_APPOINTMENTS",
  payload: data,
  loading: false,
});

export const addSubstitution = (data) => ({
  type: "ADD_SUBSTITUTION",
  payload: data,
  loading: false,
});

export const updateAppointments = (data) => ({
  type: "UPDATE_APPOINTMENTS",
  payload: data,
  loading: false,
});
export const updateSubstitution = (data) => ({
  type: "UPDATE_SUBSTITUTION",
  payload: data,
  loading: false,
});
export const deleteAppointments = (id) => ({
  type: "REMOVE_APPOINTMENTS",
  payload: id,
  loading: false,
});
export const deleteSubstitution = (id) => ({
  type: "REMOVE_SUBSTITUTION",
  payload: id,
  loading: false,
});

export const cancelAppointments = () => ({
  type: "CANCEL_APPOINTMENTS",
  payload: null,
  loading: false,
});

export const fetchTeachersPlan = (params) => (dispatch) => {
  dispatch(loadingAppointments());
  appointmentsApi
    .get(params)
    .then(({ data }) => dispatch(getTeachersPlan(data)));
};

export const fetchTeachersPlanHidden = (params) => (dispatch) => appointmentsApi.get(params).then(({ data }) => {
    dispatch(getTeachersPlan(data));
    return data;
  });
export const postAppointments = (obj) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi
    .add(obj)
    .then(({ data }) => {
      console.log("add", data);
      notification.success("Назначение создано");
      dispatch(addAppointments(data));
      return data;
    })
    .catch((err) => {
      alert(err.response.data.message);
      dispatch(cancelAppointments());
    });
};
export const postSubstitution = (obj) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi
    .add(obj)
    .then(({ data }) => {
      console.log("add", data);
      notification.success("Замена назначена");
      dispatch(addSubstitution(data));
      return data;
    })
    .catch((err) => {
      console.error(err);
      console.log("err", err);
      alert(err.response.data.message);
      dispatch(cancelAppointments());
    });
};

export const updAppointments = (obj) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi
    .update(obj)
    .then(({ data }) => {
      notification.success("Данные изменены");
      dispatch(updateAppointments(data));
    })
    .catch((err) => {
      console.error(err);
      console.log("err", err);
      alert(err.response.data.message);
      dispatch(cancelAppointments());
    });
};

export const updSubstitution = (obj) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi
    .update(obj)
    .then(({ data }) => {
      notification.success("Данные изменены");
      dispatch(updateSubstitution(data));
    })
    .catch((err) => {
      console.error(err);
      console.log("err", err);
      alert(err.response.data.message);
      dispatch(cancelAppointments());
    });
};

export const removeAppointments = (id) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi.remove(id).then(() => {
    notification.success("Назначение удалено");
    console.log("id", id);
    dispatch(deleteAppointments(id));
  });
};

export const removeAppointmentsWithoutReaload = (id) => (dispatch) =>
  appointmentsApi.remove(id).then((response) => {
    if (response.status === 200) {
      notification.success("Назначение удалено");
      dispatch(deleteAppointments(id));
    } else {
      notification.error("Назначение не удалено");
    }
    return response;
  });

export const removeSubstitute = (id) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi.remove(id).then(() => {
    notification.success("Замена удалена");
    console.log("id", id);
    dispatch(deleteSubstitution(id));
  });
};

// we can use this function to update appointments in classScheduleView
//  that the calendar with schedule does not reload and we handle the update in the background
export const updAppointmentInSchedule = (obj) => (dispatch) =>
  appointmentsApi
    .update(obj)
    .then(({ data }) => {
      notification.success("Данные расписания изменены");
      dispatch(updateAppointments(data));
      return data; // for using in classScheduleView
    })
    .catch((err) => {
      console.error(err);
      console.log("err", err);
      alert(err.response.data.message);
      dispatch(cancelAppointments());
      return null;
    });

export const postAppointmentsInSchedule = (obj) => (dispatch) =>
  appointmentsApi
    .add(obj)
    .then(({ data }) => {
      console.log("add", data);
      notification.success("Назначение создано");
      dispatch(addAppointments(data));
      return data; // for using in classScheduleView
    })
    .catch((err) => {
      alert(err.response.data.message);
      dispatch(cancelAppointments());
      return null;
    });
