import React from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import SearchAutocomplete from "../../components/SearchAutocomplete";
import DataGrid from "../../components/DataGrid/DataGrid";

import {
  getContract,
  sendUpdatedContract,
  clearContract,
} from "../../redux/actions/contractActions";
import {
  dateGetterFunc,
  dateSetterFunc,
  dateFormatterFunc,
} from "../../utils/helpers/dataGridFormatters";
import useDebounce from "../../utils/helpers/debounceHook";
import CancelReasonDialog from "./CancelReasonDialog";
import notification from "../../utils/notification/notification";
import WorkingOutPopUp from "./WorkingOutPopUp";
import { contractApi } from "../../utils/api";
import { setPageTitle } from "../../redux/actions/globalActions";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import ExtendDialog from "./ExtendDialog";
import FoodDebtDialog from "./FoodDebtDialog";

const mapStateToProps = (state) => ({
  globalState: state.globalState,
  data: state.contracts.payload,
  loading: state.contracts.loading,
  entitiesTypes: state.entitiesTypes,
  permissions: {
    add: false,
    modify: state.user.permissions.includes("contract.modify"),
    remove: false,
  },
});

const mapDispatchToProps = (dispatch) => ({
  updateData: (obj) =>
    dispatch(sendUpdatedContract(obj)).catch(() => dispatch(clearContract())),
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});

const categoryTranslate = (param) => {
  switch (param) {
    case "breakfast":
      return "завтрак";
    case "lunch":
      return "обед";
    case "snack":
      return "полдник";
    case "extension":
      return "продленка";
    case "electives":
      return "факультативы";
    case "main":
      return "договор";
    default:
      return "-";
  }
};

const columns = [
  {
    field: "date",
    headerName: "Дата",
    type: "date",
    flex: 1,
    editable: true,
    valueGetter: dateGetterFunc,
    valueSetter: (params) => dateSetterFunc(params, "date"),
    valueFormatter: dateFormatterFunc,
  },
  {
    field: "amount",
    headerName: "Сумма(Дебет)",
    flex: 1,
    editable: true,
    editableWithEmptyValue: false,
    valueFormatter: (params) => {
      if (!params.value) {
        return "-";
      }
      return `${params.value}р.`;
    },
  },
  {
    field: "credit",
    headerName: "Кредит",
    flex: 1,
    editable: false,
    valueFormatter: (params) => {
      if (!params.value) {
        return "-";
      }
      return `${params.value}р.`;
    },
  },
  {
    field: "category",
    headerName: "Вид",
    flex: 1,
    editable: false,
    valueFormatter: (params) => categoryTranslate(params.value),
  },
];

const styles = {
  subtitle: {
    paddingBottom: "10px",
  },
  span: {
    fontWeight: 400,
  },
};
function ContractsPage({
  title,
  permissions,
  setTitle,
  entitiesTypes,
  ...props
}) {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [searchLoading, setSearchLoading] = React.useState(false);
  const [contractInfo, setContractInfo] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [openExtendDialog, setOpenExtendDialog] = React.useState(false);
  const [cancelLoading, setCancelLoading] = React.useState(false);
  const [dataWorkingOut, setDataWorkingOut] = React.useState([]);
  const [kindergatens, setKindergartens] = React.useState(null);
  // console.log("data", props.data);
  const dispatch = useDispatch();
  const { id, contractNumber, name } = useParams();
  // if redirect was from studentsKanban, we use getContract action(find contracts and delete it)
  // if redirect was from debt page we use getContract action
  // (find contracts and its debit/credit)
  const historyState = useHistory().location.state;
  const debounce = useDebounce(inputValue, 1000);
  const courses = entitiesTypes.course;
  const groups = entitiesTypes.group;
  const autocompleteRef = React.useRef(null);

  const onChangeHandler = (_event, newValue) => {
    setValue(newValue);
    if (newValue) {
      dispatch(getContract(newValue.contract_id));
      setContractInfo(newValue);
    }
  };

  const onInputHandler = (_event, newInputValue) => {
    setInputValue(newInputValue);
  };

  // FoodDebtDialog
  const [openFoodDebtDialog, setOpenFoodDebtDialog] = React.useState(false);
  const handleOpenDialog = () => {
    setOpenFoodDebtDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenFoodDebtDialog(false);
  };

  // CancelReasonDialog

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleOpenPopup = () => {
    setOpenPopup(true);
    contractApi
      .getId(contractInfo.contract_id || contractInfo.id)
      .then((data) => {
        setDataWorkingOut(data.data);
      });
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  // ExtendDialog
  const handleOpenExtendDialog = () => {
    setOpenExtendDialog(true);
    contractApi.getKG().then((response) => {
      setKindergartens(response.data);
    });
  };

  const handleCloseExtendDialog = () => {
    setOpenExtendDialog(false);
  };

  const confirmCancel = (message) => {
    setOpenDialog(false);
    setCancelLoading(true);

    const contractForCancel = {
      contract_id: contractInfo.contract_id || contractInfo.id,
      rejected: message.value,
      // message,
    };

    contractApi
      .cancel(contractForCancel)
      .then(({ data: { contract } }) => {
        const { status } = contract;
        setContractInfo({
          ...contractInfo,
          status,
        });
        setCancelLoading(false);
        notification.success("Договор отменен");
      })
      .catch((err) => {
        setCancelLoading(false);
        notification.error("Не удалось отменить договор");
        console.error(err);
        alert(err.response.data.message);
      });
  };

  const statuses = {
    new: "Новый",
    active: "Активный",
    cancelled: "Отменен",
    closed: "Закрыт",
  };

  const newContract = contractInfo?.status === "new";
  const activeContract = contractInfo?.status === "active";
  const cancelledContract = contractInfo?.status === "cancelled";
  const closedContract = contractInfo?.status === "closed";
  const isChildrenClab = contractInfo?.children_club;

  // Calculate the sum of "amount" and "credit" columns
  const totalAmount = props.data?.reduce(
    (sum, row) => (parseFloat(row.amount) ? sum + parseFloat(row.amount) : sum),
    0
  );
  const totalCredit = props.data?.reduce(
    (sum, row) => (parseFloat(row.credit) ? sum + parseFloat(row.credit) : sum),
    0
  );
  const result = totalAmount - totalCredit;

  const search = (val) => {
    setSearchLoading(true);
    contractApi.get(val).then((response) => {
      const searchResult = Object.entries(response.data).map(
        ([
          key,
          {
            contract_id,
            full_name,
            number,
            status,
            course,
            phone,
            kid_name,
            children_club,
            group_id,
          },
        ]) => ({
          phone,
          contract_id,
          full_name,
          number,
          status,
          course,
          kid_name,
          children_club,
          group_id,
        })
      );
      setSearchLoading(false);
      setOptions([...searchResult]);
      return searchResult;
    });
  };

  const updateContract = (contract) => {
    dispatch(getContract(contract.contract_id)).then((dataInfo) => {
      setContractInfo({ ...dataInfo[0] });
    });
    setInputValue(contract.number);
  };

  React.useEffect(() => {
    setTitle(title);
  }, []);

  React.useEffect(() => {
    if (id && contractNumber && name && historyState === "debt") {
      dispatch(getContract(id)).then((dataInfo) => {
        setContractInfo({ ...dataInfo[0] });
      });
      setInputValue(contractNumber);
    }
    if (historyState === "studentsKanban" && id) {
      dispatch(getContract(id)).then((dataInfo) => {
        setContractInfo({ ...dataInfo[0] });
        setInputValue(dataInfo[0]?.number || dataInfo[0]?.phone || "");
      });
    }
    return () => {
      dispatch(clearContract());
    };
  }, []);

  React.useEffect(() => {
    if (debounce) {
      search(debounce);
    } else {
      setOptions([]);
    }
  }, [debounce]);

  return (
    <div className="content-component__wrapper">
      <Typography variant="h4" component="h4" className="page__title">
        {title}
      </Typography>
      <Box sx={{ padding: "0 30px 20px 30px" }}>
        <Stack>
          <Typography variant="h6" component="span" sx={styles.subtitle}>
            Номер договора:{" "}
            <span style={styles.span}>{contractInfo?.number}</span>
          </Typography>
          <Typography variant="h6" component="span" sx={styles.subtitle}>
            Клиент: <span style={styles.span}>{contractInfo?.full_name}</span>{" "}
            Ребенок: <span style={styles.span}>{contractInfo?.kid_name}</span>{" "}
            Курс:{" "}
            <span style={styles.span}>
              {findLabelByID(contractInfo?.course, courses)}
            </span>{" "}
            Группа:{" "}
            <span style={styles.span}>
              {findLabelByID(contractInfo?.group_id, groups)}
            </span>
          </Typography>
          <Typography variant="h6" component="span" sx={styles.subtitle}>
            Статус:{" "}
            <span style={styles.span}>{statuses[contractInfo?.status]}</span>
          </Typography>
        </Stack>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <SearchAutocomplete
            className="autocomplete_contract_page"
            label="Номер договора / Фамилия / Телефон"
            ref={autocompleteRef}
            value={value}
            setValue={onChangeHandler}
            inputValue={inputValue}
            setInputValue={onInputHandler}
            options={options}
            // getOptionLabel={(option) =>
            //   `${option.phone} ${option.number}
            // ${option.full_name} ${option.status} Ребенок: ${option.kid_name}`
            // }
            getOptionLabel={(option) =>
              `${option.phone},${option.number}
              ${option.full_name}
              ${option.status} 
              Ребенок: ${option.kid_name},
              Курс: ${findLabelByID(option.course, courses)}`
            }
            loading={searchLoading}
            loadingText="Поиск..."
            size="small"
            blurOnSelect
            clearOnBlur
            sx={{
              width: {
                sm: "85%",
                lg: "30%",
              },
              "& .MuiAutocomplete-option": {
                fontSize: "0.2rem", // Adjust font size here
              },
            }}
          />
          {permissions.modify && contractInfo && (
            <div style={{ display: "flex", gap: "20px", marginLeft: "20px" }}>
              <Tooltip
                title={
                  (!newContract &&
                    'Отменить можно только договор со статусом "Новый" и "Активный"') ||
                  ""
                }
                placement="bottom"
              >
                <span>
                  <LoadingButton
                    size="small"
                    variant="contained"
                    onClick={handleOpen}
                    loading={cancelLoading}
                    disabled={!(newContract || activeContract)}
                  >
                    Отменить договор{" "}
                  </LoadingButton>
                </span>
              </Tooltip>
              <Tooltip
                title={
                  (!isChildrenClab &&
                    "Продлить можно только договора на Сад") ||
                  ""
                }
                placement="bottom"
              >
                <span>
                  <LoadingButton
                    size="small"
                    variant="contained"
                    disabled={!isChildrenClab}
                    onClick={handleOpenExtendDialog}
                  >
                    продлить договор{" "}
                  </LoadingButton>
                </span>
              </Tooltip>
              <span>
                <LoadingButton
                  size="small"
                  variant="contained"
                  onClick={handleOpenPopup}
                  loading={cancelLoading}
                  disabled={cancelledContract || closedContract}
                >
                  Отработки{" "}
                </LoadingButton>
              </span>
              <span>
                <LoadingButton
                  size="small"
                  variant="contained"
                  onClick={handleOpenDialog}
                  loading={cancelLoading}
                  disabled={cancelledContract || closedContract}
                >
                  Доп. услуги{" "}
                </LoadingButton>
              </span>
            </div>
          )}
        </Box>
      </Box>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          gap: "20px",
          marginRight: "5%",
        }}
      >
        <div>
          {" "}
          <strong>Общий Дебет:</strong> {(+totalAmount).toFixed(2)}р.{" "}
        </div>
        <div>
          {" "}
          <strong>Общиий Кредит:</strong> {(+totalCredit).toFixed(2)}р.{" "}
        </div>

        {result >= 0 ? (
          <div>
            <strong>Остаток по договору: </strong> {(+result).toFixed(2)}{" "}
          </div>
        ) : (
          <div>
            {" "}
            <strong>Переплата клиента: </strong>
            <span style={{ color: "#f44336" }}>
              {" "}
              {(+result).toFixed(2)}
            </span>{" "}
          </div>
        )}
      </div>
      <DataGrid
        columns={columns}
        entityName="debt_and_credit"
        getRowId={(row) => `${row.id || 0}${row.contract_id}${row.type}`}
        permissions={permissions}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "date",
                sort: "desc",
              },
            ],
          },
        }}
        {...props}
      />
      <CancelReasonDialog
        open={openDialog}
        handleClose={handleClose}
        confirm={confirmCancel}
      />
      <WorkingOutPopUp
        open={openPopup}
        handleClose={handleClosePopup}
        dataWorkingOut={dataWorkingOut}
        contractInfo={contractInfo}
      />
      <ExtendDialog
        open={openExtendDialog}
        handleClose={handleCloseExtendDialog}
        contractInfo={contractInfo}
        kindergatens={kindergatens}
        entitiesTypes={entitiesTypes}
      />
      <FoodDebtDialog
        open={openFoodDebtDialog}
        handleClose={handleCloseDialog}
        contractInfo={contractInfo}
        updateContract={updateContract}
        data={props.data}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractsPage);
