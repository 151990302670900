/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-quotes */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import "./AttendanceReportsPage.scss";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import dayjs from "dayjs";
import minMax from "dayjs/plugin/minMax";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import { Calendar } from "../ReusableInputs/ReusableInputs";
import "dayjs/locale/ru"; // import russian locale
import { attendanceApi } from "../../utils/api";
import { DataGridPro } from "../../dataGridLib/x-data-grid-pro";
import BackdropPartOfScreen from "../Loaders/BackdropPartOfScreen";
import { sortByField } from "../../utils/helpers/sorting";
import StudentInfoModal from "./StudentInfoModal";

dayjs.locale("ru"); // use russian locale globally
dayjs.extend(minMax);

const AttendanceReports = ({
  title,
  initFilters,
  permissions,
  entitiesTypes,
  setTitle,
  loading,
  ...props
}) => {
  const [errorText, setError] = useState("");
  const [studentInfo, setStudentInfo] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const teachers = entitiesTypes.user;

  const initialFiltersState = {
    groups: null,
    user_id: null,
    subjects: null,
  };

  const initialInputsState = {
    groups: "",
    user_id: "",
    subjects: "",
  };

  const dataGridRef = React.useRef(null);
  const [filters, setFilters] = useState(initFilters?.filters[0] || {});
  const [inputs, setInputs] = React.useState(initialInputsState);
  const [reports, setReports] = React.useState(null);
  // create a copy of reports to use it for filtering by date
  // (avoid changing the original array of reports)
  const [reportsCopy, setReportsCopy] = React.useState(null);
  const [minmax, setMinMax] = React.useState({}); // for filter by date

  const [avaliableGroups, setAvaliableGroups] = React.useState([]);
  const [avaliableSubjects, setAvaliableSubjects] = React.useState([]);
  const [avaliableTeachers, setAvaliableTeachers] = React.useState([]);

  const [loadingData, setLoadingData] = React.useState(false);
  const location = useLocation();
  console.log("location", location);
  const { groupID } = useParams();
  console.log("id", groupID);

  // Function to scroll the DataGrid to the end horizontally
  const scrollDataGridToEnd = (groupId) => {
    if (dataGridRef.current) {
      const dataGridMainElements = document.querySelectorAll(
        ".MuiDataGrid-main .MuiDataGrid-virtualScroller"
      );
      dataGridMainElements.forEach((item) => {
        if (
          item.parentElement.parentElement.parentElement?.classList.contains(
            `table_${groupId}`
          )
        ) {
          item.scrollLeft = item.scrollWidth;
        }
      });
    }
  };

  const getStatusTranslate = (status) => {
    switch (status) {
      case "new":
        return "запланировано";
      case "done":
        return "проведено";
      case "gap":
        return "пропущено";
      default:
        return null;
    }
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case "new":
        return <ScheduleIcon style={{ color: "#007bff" }} />;
      case "done":
        return <CheckCircleIcon style={{ color: "#2a7b3d" }} />;
      case "gap":
        return <ErrorIcon style={{ color: "#dc3545" }} />;
      default:
        return null;
    }
  };

  // prepeare object with labels for sorting and useful rendering
  const createObjWithLabels = (filter, entities) => {
    if (!filter || !entities) {
      return;
    }
    const transformedValues = filter
      .map((value) => {
        const filtered = entities.find((entites) => entites.value === value);
        return filtered
          ? { value: filtered.value, label: filtered.label }
          : null;
      })
      .filter(Boolean);
    return transformedValues.sort(sortByField("label"));
  };

  React.useEffect(() => {
    setTitle(title);
  }, []);

  React.useEffect(() => {
    setFilters(initFilters?.filters[0] || {});
  }, [initFilters]);

  React.useEffect(() => {
    if (!inputs.groups) {
      const findedGroups = createObjWithLabels(
        filters.groups,
        entitiesTypes.group
      );

      setAvaliableGroups(findedGroups);
    }
    if (!inputs.user_id) {
      const findedTeachers = createObjWithLabels(
        filters.user_id,
        entitiesTypes.user
      );
      setAvaliableTeachers(findedTeachers);
    }
    if (!inputs.subjects) {
      const findedSubjects = createObjWithLabels(
        filters.subjects,
        entitiesTypes.subject
      );
      setAvaliableSubjects(findedSubjects);
    }
  }, [filters.subjects, filters.user_id, filters.groups]);

  const callApi = (params) => {
    if (!params) {
      return;
    }
    setLoadingData(true);
    attendanceApi.getReport(params).then((response) => {
      if (response.status === 200) {
        setReports(
          response?.data?.report && response?.data?.report?.length !== 0
            ? response?.data?.report
            : null
        );
        setReportsCopy(
          response?.data?.report && response?.data?.report?.length !== 0
            ? response?.data?.report
            : null
        );
        setFilters((prevFilters) => ({
          ...prevFilters,
          groups: response.data.filters[0].groups,
          user_id: response.data.filters[0].user_id,
          subjects: response.data.filters[0].subjects,
        }));
        // } else {
        //   setFilters(
        //     response.data.filters[0].filters || response.data.filters[0]
        //   );
        // }
        setLoadingData(false);
      } else {
        setError("Ошибка при получении данных");
      }
    });
  };

  const handleCleanInputs = () => {
    setInputs(initialInputsState);
    setFilters(initialFiltersState);
    setDateRange([null, null]);
    setReports(null);
    callApi(initialInputsState);
  };

  const handleInputsChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputsState) => ({
      ...prevInputsState,
      [name]: value,
    }));
    callApi({ ...inputs, [name]: value });
  };

  React.useEffect(() => {
    if (reports) {
      const allGroupDates = reportsCopy
        .map((report) => report.lessons.map((obj) => obj.date))
        .flat();
      const largestDate = Math.max(
        ...allGroupDates.map((date) => new Date(date).getTime())
      );
      const smallestDate = Math.min(
        ...allGroupDates.map((date) => new Date(date).getTime())
      );
      setMinMax({ min: new Date(smallestDate), max: new Date(largestDate) });
    }
  }, [reportsCopy]);

  // If the user passes a date interval, the reports array will change.
  // therefore, when opening the calendar, we set copy of reports that we made earlier
  const handleCalendarOpen = () => {
    setReports((prevState) => [...prevState, ...reportsCopy]);
  };

  const handleDateChange = (newDateRange) => {
    setDateRange(newDateRange);
    if (dateRange[0] && dateRange[1]) {
      const formattedStartDate = dayjs(dateRange[0]).format("YYYY.MM.DD");
      const formattedEndDate = dayjs(dateRange[1]).format("YYYY.MM.DD");
      const filteredByDates = reports
        .map((group) => {
          const filteredLessons = group.lessons.filter((lesson) => {
            const itemDate = dayjs(lesson.date).format("YYYY.MM.DD");
            return (
              itemDate >= formattedStartDate && itemDate <= formattedEndDate
            );
          });
          if (filteredLessons.length > 0) {
            return { ...group, lessons: filteredLessons };
          }
          return null;
        })
        .filter(Boolean);
      setReports([...filteredByDates]);
    }
  };

  const handleRowDetails = (row, event) => {
    event.defaultMuiPrevented = false;
    if (row.colDef.headerName === "ФИО") {
      // eslint-disable-next-line no-useless-return
      return;
    }
    const studentInformation = {};
    const lessonId = row.field;
    const studentId = row.row.id.toString();
    const groupInfo = reports
      .map((group) => {
        const foundLesson = group.lessons.find(
          (lesson) => lesson.lesson_id === lessonId.toString()
        );
        if (foundLesson) {
          return group;
        }
        return null; // If no matching lesson is found in the group
      })
      .filter(Boolean)[0];
    const lesson = reports
      .flatMap((group) =>
        group.lessons.map((l) => (l.lesson_id === lessonId ? l : undefined))
      )
      .filter(Boolean)[0];
    studentInformation.group_id = groupInfo.group_id;
    studentInformation.lesson_id = lessonId;
    studentInformation.teacher = lesson.teacher;
    studentInformation.topic = lesson.topic;
    studentInformation.date = lesson.date;
    studentInformation.subject = lesson.subject;
    studentInformation.home_work = lesson.home_work;
    const targetStudent = lesson.students.find(
      (item) => item.id.toString() === studentId
    );
    if (!targetStudent) {
      return;
    }
    console.log("lesson", lesson);
    studentInformation.targetStudent = targetStudent;
    setStudentInfo(studentInformation);
    // document.body.style.overflow = "hidden";
  };

  const [workOffStudents, setWorkOffStudents] = useState([]);
  const findWorkOff = () => {
    const workOff = reports.flatMap((group) =>
      group.lessons.flatMap((lesson) =>
        lesson.students.filter((student) => student.description === "отработка")
      )
    );
    const uniqueObjects = new Set();

    // Filter and map the data array to return truncated objects with selected properties
    const truncatedData = workOff.reduce((acc, item) => {
      const truncatedObject = Object.keys(item)
        .filter((key) => ["id", "name"].includes(key))
        .reduce((obj, key) => {
          obj[key] = item[key];
          return obj;
        }, {});

      // Convert the truncated object to a string representation
      const truncatedString = JSON.stringify(truncatedObject);

      // Check if the string representation is not already in the set
      if (!uniqueObjects.has(truncatedString)) {
        uniqueObjects.add(truncatedString);
        acc.push(truncatedObject);
      }

      return acc;
    }, []);
    setWorkOffStudents([...truncatedData]);
  };
  React.useEffect(() => {
    if (reports) {
      findWorkOff();
    }
  }, [reports]);

  const bottomOfPageRef = React.useRef(null);

  const scrollPage = () => {
    if (bottomOfPageRef.current) {
      bottomOfPageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const getRowClassName = (params) => {
    const isWorkingOff = workOffStudents.some(
      (item) => item.id.toString() === params.row.id.toString()
    );
    return isWorkingOff ? "workingOff" : "";
  };

  React.useEffect(() => {
    if (groupID && location.state === "studentsKanban") {
      callApi({ ...inputs, groups: groupID });
    }
  }, []);

  if (errorText) {
    return <div>{errorText}</div>;
  }

  if (loadingData) {
    return <BackdropPartOfScreen open={loadingData} />;
  }

  return (
    <div className="content-component__wrapper">
      <Typography variant="h4" component="h4" className="page__title">
        {title}
      </Typography>

      <div>
        {loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="inherit" size={30} />
          </div>
        ) : (
          <div className="attendance__report_filters_wrapper">
            <div className="attendance__report_filters">
              <FormControl sx={{ width: 200 }}>
                <InputLabel htmlFor="group">Группа</InputLabel>
                <Select
                  value={inputs.groups}
                  onChange={handleInputsChange}
                  size="small"
                  label="Группа"
                  name="groups"
                >
                  {avaliableGroups?.map((group) => (
                    <MenuItem value={group.value} key={group.value}>
                      {group.label}
                    </MenuItem>
                  ))}
                  <MenuItem value="">не выбрано</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ ml: 3, width: 200 }}>
                <InputLabel htmlFor="teacher">Преподаватель</InputLabel>
                <Select
                  value={inputs.user_id}
                  onChange={handleInputsChange}
                  size="small"
                  label="Преподаватель"
                  name="user_id"
                >
                  {avaliableTeachers?.map((teacher) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem value={teacher.value} key={teacher.value}>
                      {teacher.label}
                    </MenuItem>
                  ))}
                  <MenuItem value="">не выбрано</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ ml: 3, width: 200 }}>
                <InputLabel htmlFor="teacher">Предмет</InputLabel>
                <Select
                  value={inputs.subjects}
                  onChange={handleInputsChange}
                  size="small"
                  label="Предмет"
                  name="subjects"
                >
                  {avaliableSubjects?.map((subject) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem value={subject.value} key={subject.value}>
                      {subject.label}
                    </MenuItem>
                  ))}
                  <MenuItem value="">не выбрано</MenuItem>
                </Select>
              </FormControl>
              <div style={{ marginLeft: "20px", width: "250px" }}>
                <Calendar
                  handleDateChange={handleDateChange}
                  handleCalendarOpen={handleCalendarOpen}
                  dateRange={dateRange}
                  minmax={minmax}
                  disabled={!reports}
                />
              </div>
            </div>
            <div className="attendance__report_btns">
              <Button onClick={handleCleanInputs} variant="contained">
                Сбросить
              </Button>
            </div>
          </div>
        )}

        {!reports && (inputs.groups || inputs.subjects || inputs.groups) && (
          <h4 style={{ marginLeft: "10px" }}>
            Данныe по вашему запросу не найдены. Выберите другие данные или
            нажмите кнопку &#34; Сбросить &#34; и выберите фильтры заново
          </h4>
        )}
        {!inputs.groups && !inputs.subjects && !inputs.groups && (
          <h4 style={{ marginLeft: "10px" }}>
            Выберите один или несколько фильтров для отображения данных. Чтобы
            сбросить фильтры нажмите кнопку &#34; Сбросить &#34;. <br /> При
            нажатии на ячейку в таблице, откроется окно с подробной информацией
            о занятии.
          </h4>
        )}
        <div>
          <Button
            onClick={() => scrollPage()}
            variant="contained"
            size="small"
            className="attendance__down_btn"
          >
            Вниз
          </Button>
          {reports &&
            reports.map((group) => (
              <div
                key={Math.random(100)}
                style={{ marginBottom: "50px" }}
                ref={bottomOfPageRef}
              >
                <style>{`
                    .MuiDataGrid-footerContainer {
                     display: none;
                               }
                      `}</style>
                <h3>
                  Группа: {findLabelByID(group.group_id, entitiesTypes.group)}
                </h3>
                {studentInfo && (
                  <StudentInfoModal
                    entitiesTypes={entitiesTypes}
                    studentInfo={studentInfo}
                    onClose={() => setStudentInfo(null)}
                  />
                )}
                <Box
                  style={{ width: "100%", overflowX: "auto" }}
                  sx={{
                    "& .super-app-theme--cell": {
                      backgroundColor: "rgba(224, 183, 60, 0.55)",
                      color: "#1a3e72",
                      fontWeight: "600",
                      border: "1px solid #000000",
                      cursor: "pointer",
                    },
                    "& .super-app.negative": {
                      backgroundColor: "rgba(140, 255, 111, 0.747)",
                      color: "#1a3e72",
                      fontWeight: "600",
                      border: "1px solid #000000",
                      cursor: "pointer",
                    },
                    "& .super-app.workingOff": {
                      backgroundColor: "rgba(38, 56, 49, 0.46)",
                      color: "#1a3e72",
                      fontWeight: "600",
                      border: "1px solid #000000",
                      cursor: "pointer",
                    },
                    "& .super-app.positive": {
                      backgroundColor: "#fa8e64",
                      color: "#1a3e72",
                      fontWeight: "600",
                      border: "1px solid #000000",
                      cursor: "pointer",
                    },
                    "& .super-app-theme--header": {
                      transform: "rotate(180deg)",
                      writingMode: "vertical-rl",
                      overflow: "hidden",
                      lineHeight: "20px",
                      whiteSpace: "normal",
                      display: "flex",
                      flexWrap: "wrap",
                    },
                  }}
                >
                  <DataGridPro
                    columns={[
                      { field: "id", headerName: "Student ID", hide: true },
                      {
                        field: "group_id",
                        headerName: "Group Id",
                        hide: true,
                      },
                      { field: "name", headerName: "ФИО", width: 200 },
                      ...group.lessons.map((lesson) => ({
                        field: lesson.lesson_id,
                        headerName: (
                          <span>
                            {dayjs(lesson.date).format("DD MMMM HH:mm")} <br />{" "}
                            {findLabelByID(
                              lesson.subject,
                              entitiesTypes.subject
                            )}
                          </span>
                        ),
                        headerClassName: "super-app-theme--header",
                        renderCell: (params) => {
                          const student =
                            lesson.students.find(
                              (s) =>
                                s.id.toString() === params.row.id.toString()
                            ) || {};
                          if (
                            !params.row[lesson.lesson_id] &&
                            student.description === "отработка"
                          ) {
                            return "отработка";
                          }
                          if (
                            !params.row[lesson.lesson_id] &&
                            student.attendance_status
                          ) {
                            return (
                              <Tooltip
                                title={
                                  <>
                                    {getStatusTranslate(
                                      student.attendance_status
                                    )}
                                    <br />
                                    {student.attendance_date}
                                  </>
                                }
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: "offset",
                                      options: {
                                        offset: [0, 8],
                                      },
                                    },
                                  ],
                                  sx: {
                                    "& .MuiTooltip-tooltip": {
                                      fontSize: "1.3rem",
                                      padding: "10px",
                                    },
                                  },
                                }}
                              >
                                {getStatusIcon(student.attendance_status)}
                              </Tooltip>
                            );
                          }
                          return student?.review || "";
                        },
                        cellClassName: (params) => {
                          const infoAboutStudent = lesson.students.find(
                            (student) =>
                              student.id.toString() === params.row.id.toString()
                          );
                          if (infoAboutStudent)
                            return clsx("super-app", {
                              negative: infoAboutStudent.isPresence === true,
                              positive: infoAboutStudent.isPresence === false,
                              // workingOff: infoAboutStudent.description === "отработка",
                            });
                        },
                      })),
                      { field: "name", headerName: "ФИО", width: 200 },
                    ].reverse()}
                    ref={dataGridRef}
                    className={`attendance__report_table table_${group.group_id}`}
                    headerHeight={300}
                    onCellClick={(event, row) => handleRowDetails(event, row)}
                    fixedHeader={false}
                    density="compact"
                    getRowClassName={getRowClassName}
                    autoHeight
                    permissons={permissions}
                    style={{ width: "auto", tableLayout: "auto" }}
                    entityName="attendance"
                    getRowId={(row) => `${row.id}`}
                    rows={group.default_list_students.concat(workOffStudents)}
                    scrollEndThreshold={100}
                  />
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {
                      scrollDataGridToEnd(group.group_id);
                    }}
                  >
                    в конец таблицы
                  </Button>
                </Box>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(AttendanceReports);
