import { contractApi } from "../../utils/api";
import notification from "../../utils/notification/notification";

export const setContract = (data) => ({
  type: "SET_CONTRACT",
  payload: data,
  loading: false,
});

export const clearContract = () => ({
  type: "CLEAR_CONTRACT",
});

export const updateContract = (data) => ({
  type: "UPDATE_CONTRACT",
  payload: data,
  loading: false,
});

export const loadingContracts = () => ({
  type: "LOADING_CONTRACT",
  loading: true,
});

export const loadReasons = (data) => ({
  type: "LOAD_REASONS",
  payload: data,
});

export const getAllContracts = (data) => ({
  type: "GET_CONTRACTS",
  payload: data,
  loading: false,
});



export const getContract = (contract_id) => (dispatch) => {
  dispatch(loadingContracts());
  return contractApi.get(contract_id).then(({ data }) => {
    console.log("data", data);
    dispatch(setContract(data[0].debt));
    return data;
  });
};


export const getContractToExtend = (contract_id) => (dispatch) => {
  dispatch(loadingContracts());
  return contractApi.get(contract_id).then(({ data }) => {
    if(!data || data.length === 0) { 
      notification.error("Не удалось продлить договор(договор не активен)");
      return;
    }
    console.log("data", data);
    dispatch(setContract(data[0].debt));
    // eslint-disable-next-line consistent-return
    return data;
  });
};

export const getContracts = () => (dispatch) => {
  dispatch(loadingContracts());
  return contractApi.getAllContracts().then(({ data }) => {
    dispatch(getAllContracts(data.contracts));
  });
};

export const sendUpdatedContract = (obj) => (dispatch) => {
  dispatch(loadingContracts());
  return contractApi.update({ ...obj }).then(({ data }) => {
    notification.success("Договор обновлен");
    dispatch(updateContract(data.debt_and_credit));
  });
};

export const getReasonsList = () => (dispatch) =>
  contractApi.getList().then((reasons) => dispatch(loadReasons(reasons.data)));
