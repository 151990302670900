/* eslint-disable no-nested-ternary */
import { Backdrop, CircularProgress } from "@mui/material";
import { React, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchTeachersPlan,
  postAppointmentsInSchedule,
  postSubstitution,
  removeAppointments,
  removeSubstitute,
  updAppointmentInSchedule,
  updSubstitution,
} from "../../redux/actions/appointmentsActions";
import "../../scss/index.scss";
import { setPageTitle } from "../../redux/actions/globalActions";
import ClassScheduleView from "../../components/ClassSchedule/ClassScheduleView";
import { getUserLocation } from "../../utils/helpers/getDefaultUser";
import SimpleTabs from "../../components/TabsComponent/SimpleTabs";
import AdvicePopup from "../../components/AdvicePopup/AdvicePopup";

const mapStateToProps = (state) => ({
  loading: state.appointmentsAndSubstitute.loading,
  appointments: state.appointmentsAndSubstitute.appointments,
  globalState: state.globalState,
  globalLoading: state.globalState.loading,
  entitiesTypes: state.entitiesTypes,
  config: state.appointmentsAndSubstitute.config,
  permissions: {
    add: false,
    modify: true,
    remove: true,
  },
});

const mapDispatchToProps = (dispatch) => ({
  getTeachersPlan: (params) => {
    dispatch(fetchTeachersPlan(params));
  },
  addAppointment: (data) => dispatch(postAppointmentsInSchedule(data)),
  updAppointment: (data) => dispatch(updAppointmentInSchedule(data)),
  addSubstitution: (data) => {
    dispatch(postSubstitution(data));
  },
  updateSubstitution: (data) => {
    dispatch(updSubstitution(data));
  },
  removeSub: (id) => {
    dispatch(removeSubstitute(id));
  },
  removeAssigned: (id) => {
    dispatch(removeAppointments(id));
  },
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});

function ClassSchedulePage({ ...props }) {
  const {
    loading,
    getTeachersPlan,
    title,
    appointments,
    globalLoading,
    config,
    setTitle,
  } = props;
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleLocationChange = (value) => {
    setSelectedLocation(value);
  };

  useEffect(() => {
    setTitle(title);
  }, []);

  useEffect(() => {
    const userLocations = getUserLocation() || [];
    setLocations(userLocations);
    const firstLocation = userLocations[0] || null;
    setSelectedLocation(firstLocation);
  }, []);

  useEffect(() => {
    if (selectedLocation) {
      getTeachersPlan(selectedLocation);
    }
  }, [selectedLocation]);

  return (
    <>
      <AdvicePopup>
        <strong>
          {" "}
          &#128274; &#128274;!для возможности редактировать нажмите замок в
          верхней части страницы! &#128274; &#128274; <br />
          <span>
            ------------------------------------------------------------
          </span>
          <br />
        </strong>
        <strong>Выберите нужную локацию и неделю в шапке страницы </strong>
        <br />
        <strong>Редактирование урока: </strong> Дважды кликните на существующий
        урок, чтобы отредактировать его. <br />
        <strong>Создание урока:</strong> Дважды кликните на пустую клетку, чтобы
        создать новый урок. <br />
        <strong>Перемещение урока:</strong> Перетащите урок на другой день или
        время, чтобы автоматически обновить время начала занятия и день. Либо
        укажите другой день/время при редактировании <br />
        <strong>Синхронизация изменений:</strong> Все изменения немедленно
        синхронизируются с сервером. Любые правки, внесённые здесь, будут
        отражены и во вкладке Назначения и замены, и наоборот. Так же в
        Расписание занятий.
      </AdvicePopup>
      <div className="content-component__wrapper">
        <h4 className="table__title">{title}</h4>
        <SimpleTabs
          locations={locations}
          onLocationChange={handleLocationChange}
          selectedLocation={selectedLocation}
        />
        {loading || globalLoading ? (
          <Backdrop open sx={backdropStyles}>
            <CircularProgress />
          </Backdrop>
        ) : appointments.length === 0 || config.length === 0 ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "70vh",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Нет данных для отображения
          </div>
        ) : (
          <ClassScheduleView
            {...props}
            locations={locations}
            selectedLocation={selectedLocation}
          />
        )}
      </div>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ClassSchedulePage);
