const initialState = {
	payload: [],
	loading: false,
	error: ""
};

const creatingCustomersReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_CUSTOMERS":
			return {
				...state,
				payload: action.payload,
				loading: action.loading,
			};
		case "LOADING_CUSTOMERS":
			return {
				...state,
				loading: action.loading,
			};
		case "ADD_CUSTOMER":
			return {
				...state,
				payload: [...state.payload],
				loading: action.loading,
			};
		case "UPDATE_CUSTOMER":
			return {
				...state,
				payload: state.payload.map((item) =>  {
					if (item.id_user === action.payload.id_user) {
						item = action.payload;
					}
					return item;
				}
				),
				loading: action.loading,
			};
		case "REMOVE_CUSTOMER":
			return {
				...state,
				payload: state.payload.map((item) => 
				(item.id_user === action.payload.id_user ? action.payload : item)
			),
				loading: action.loading,
			};
			case "ENABLE_CUSTOMER":
				return {
					...state,
					payload: state.payload.map((item) =>  {
						if (item.id_user === action.payload.id) {
							item.status = action.payload.status;
						}
						return item;
					}
					// (item.id_user === action.payload.id_user ? action.payload : item)
				),
					loading: action.loading,
				};
			case "CANCEL_CUSTOMERS_POST":
				return {
					...state,
					loading: action.loading,
				};
			
		default:
			return state;
	}
};

export default creatingCustomersReducer;
