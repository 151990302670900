/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar,
  Button,
  ToggleButton,
  ToggleButtonGroup,
  Chip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";

import CloseIcon from "@mui/icons-material/Close";
import { set } from "date-fns";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import "./TaskModal.scss";
import { lessonsApi, tasksApi } from "../../utils/api";
import {
  ReusableTextField,
  RussianDatePicker,
} from "../ReusableInputs/ReusableInputs";
import notification from "../../utils/notification/notification";
import { addNewTask } from "../../redux/actions/tasksActions";
import SimpleConfirmModal from "../ReusableSimpleConfirmatin/SimpleConfirmModal";
import downloadFile from "../../utils/helpers/downloadFile";

const TaskModalCreate = ({ open, onClose, task }) => {
  const managers = useSelector(({ entitiesTypes }) => entitiesTypes?.user);
  const customers = useSelector(
    ({ entitiesTypes }) => entitiesTypes?.customers
  );
  const allUsers = managers.concat(customers);
  const defaultUser = useSelector(({ user }) => user.info);
  const tasksCreator = findLabelByID(defaultUser.id.toString(), allUsers);
  const tasksExecutor = findLabelByID(task.executor, managers);
  const dispatch = useDispatch();
  // console.log("defaultUser", defaultUser);

  const [role, setRole] = React.useState("metodist");

  const [users, setUsers] = React.useState([]);
  const today = dayjs().format("YYYY-MM-DD 00:00:00");
  const dayAfterTwo = dayjs().add(2, "day").format("YYYY-MM-DD 00:00:00");

  // add files
  // const [fileInfo, setFileInfo] = useState([]);
  // const [filePreviews, setFilePreviews] = useState([]);
  const [attachments, setAttachments] = React.useState([]);

  // const clearFileState = () => {
  //   setFileInfo([]);
  //   setFilePreviews([]);
  //   setAttachments([]);
  // };

  // const handleFilesChange = (event) => {
  //   const f = event.target.files[0];
  //   if (f) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       setFilePreviews([...filePreviews, e.target.result]);
  //     };
  //     reader.readAsDataURL(f);
  //   }
  //   if (!event.target.files[0]) {
  //     return;
  //   }
  //   setFileInfo((prev) => [...prev, f.name]);
  //   console.log("fileInfo", fileInfo);

  //   const formData = new FormData();
  //   formData.append("image", event.target.files[0]);
  //   formData.append("lesson_id", "");
  //   lessonsApi
  //     .upload(formData)
  //     .then((response) => {
  //       setAttachments([...attachments, response.data.id_attachment]);
  //       notification.success(`файл ${f.name} добавлен`);
  //     })
  //     .catch((e) => alert(`Произошла ошибка : ${e.message}, ${e.code}`));
  // };

  // const removeFile = (indexToRemove) => {};

  const findUsersByRole = (r, entities) => {
    const arr = [];
    entities.filter((item) =>
      r?.forEach((rol) => {
        if (item.value === rol.toString()) {
          arr.push(item);
        }
      })
    );
    return arr;
  };

  const initialTaskState = {
    title: "",
    description: "",
    new_comment: "",
    executor: "",
    id_attachments: attachments || [],
    date_due: role === "metodist" ? dayAfterTwo || "" : today || "",
  };
  const [taskValues, setTaskValues] = React.useState(initialTaskState);

  const handleModalClose = () => {
    setTaskValues(initialTaskState);
    onClose(false);
  };

  const handleDateChange = (newValue) => {
    const formattedValue = newValue
      ? newValue.format("YYYY-MM-DD 00:00:00")
      : "";
    setTaskValues((prevState) => ({
      ...prevState,
      date_due: formattedValue,
    }));
    // console.log("taskValues", taskValues);
  };

  const handleInputsChange = (event) => {
    const { name, value } = event.target;
    setTaskValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // console.log("taskValues", taskValues);
  };

  const handleRoleChange = (event, newRole) => {
    if (newRole) {
      setRole(newRole);
    }
    setTaskValues((prevState) => ({
      ...prevState,
      date_due: newRole === "metodist" ? dayAfterTwo : today,
    }));
  };

  const [expandComment, setExpandComment] = React.useState(false);
  const handleExpandComment = () => {
    setExpandComment(!expandComment);
  };

  // simpleModal Notification when user send task
  const [isNotificationOpen, setNotificationOpen] = useState(false);

  const openNotificationModal = () => {
    setNotificationOpen(true);
  };
  const closeNotificationModal = () => {
    setNotificationOpen(false);
  };
  const hanleConfirm = () => {
    closeNotificationModal();
    handleModalClose();
  };

  const handleTaskSubmit = () => {
    const submit = {
      title: taskValues.title,
      description: taskValues.description,
      type: role,
      executor: taskValues.executor,
      date_due: taskValues.date_due,
      new_comment: taskValues.new_comment,
      id_attachments: taskValues.id_attachments,
    };
    dispatch(addNewTask(submit));
    console.log(submit);
    openNotificationModal();
  };

  const handleTaskCancel = () => {
    handleModalClose();
  };

  React.useEffect(() => {
    if (open) {
      tasksApi.roles().then((data) => setUsers(data.data));
      // console.log("usersByRole:", users);
      // console.log(
      //   "users[role]",
      //   users[role]?.map((user) => findLabelByID(user, managers))
      // );
    }
  }, [open]);

  const [filePreviews, setFilePreviews] = useState([]);
  const [fileArray, setFilesArray] = useState([]);

  const handleFilesChange = async (e) => {
    console.log("e.target.files", e.target.files);
    const files = Array.from(e.target.files);
    console.log("files", files);
    const newPreviews = files.map((file) => URL.createObjectURL(file));
    const newInfo = files.map((file) => file);

    setFilePreviews((prevPreviews) => [...prevPreviews, ...newPreviews]);
    setFilesArray((prevInfo) => [...prevInfo, ...newInfo]);
    console.log("fileArray", fileArray);
    try {
      const formData = new FormData();
      formData.append("image", e.target.files[0]);
      formData.append("lesson_id", "");
      const uploadResult = await lessonsApi.upload(formData);
      console.log("All files uploaded:", uploadResult);
      notification.success("Файл успешно загружен");
      console.log("uploadResult", uploadResult);

      const lastUploadedId = uploadResult.data.id_attachment;
      // Safely updating id_attachments
      setTaskValues((prevState) => ({
        ...prevState,
        id_attachments: prevState.id_attachments
          ? [...prevState.id_attachments, lastUploadedId]
          : [lastUploadedId],
      }));
      console.log("taskValues", taskValues);
      setFilesArray((prevInfo) => {
        const updatedInfo = [...prevInfo];
        updatedInfo[updatedInfo.length - 1].id_attachment = lastUploadedId;
        return updatedInfo;
      });
    } catch (error) {
      console.log("Error uploading files:", error);
    }
  };

  const removeFile = (index, id) => {
    lessonsApi.remove(id).then((resp) => {
      if (resp.data === "ok") {
        const newPreviews = [...filePreviews];
        const newInfo = [...fileArray];
        newPreviews.splice(index, 1);
        newInfo.splice(index, 1);
        setFilePreviews(newPreviews);
        setFilesArray(newInfo);
      }
    });
  };

  const downloadF = (file) => {
    console.log("file", file);
    lessonsApi.download(file.id).then((response) => {
      downloadFile(response, file.file_name);
    });
  };

  if (!open) return null;
  return (
    <>
      <div className="popup__wrapper">
        <div className="popup-overlay" />
        <div className="task__popup">
          <div className="popup__container">
            <CloseIcon
              style={{
                position: "absolute",
                right: "0%",
                top: "0%",
                cursor: "pointer",
                margin: "5px",
              }}
              onClick={handleModalClose}
            />
            <div className="popup__left">
              <header className="popup__header">
                {/* <div className="popup__title">{task.title}</div> */}
              </header>

              <main className="popup__main">
                {/* <div className="popup__subtitle">Описание задачи:</div>
                <div className="popup__descr">{task.description}</div>
                <div className="popup__subtitle">Комментарии</div>
                <div className="popup__descr">{task.comments}</div> */}
                <div>
                  <ReusableTextField
                    type="text"
                    label="Название"
                    value={taskValues.title}
                    name="title"
                    onChange={handleInputsChange}
                  />

                  <div>
                    <ReusableTextField
                      label="Описание"
                      multiline
                      rows={4}
                      value={taskValues.description}
                      name="description"
                      onChange={handleInputsChange}
                    />
                  </div>

                  <div className="tasks__file_upload">
                    <Typography
                      component="label"
                      edge="end"
                      className="upload_span_element"
                    >
                      добавить файл:
                      <input type="file" hidden onChange={handleFilesChange} />
                    </Typography>

                    <div className="file-upload-container">
                      <div style={{ marginTop: 16 }}>
                        {filePreviews.map((f, index) => (
                          <div
                            // eslint-disable-next-line react/no-array-index-key
                            key={fileArray[index]?.name + index}
                            style={{ marginBottom: 8 }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {filePreviews[index] ? (
                                <div className="file-chip">
                                  <Chip
                                    label={
                                      fileArray[index]?.name || `file-${index}`
                                    }
                                    onDelete={() =>
                                      removeFile(
                                        index,
                                        fileArray[index].id_attachment
                                      )
                                    }
                                    className="file-chip"
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {task && task.id_attachments && task.id_attachments.length > 0
                    ? task.id_attachments.map((file, index) => (
                        <div className="">
                          {file.file_name}
                          <span
                            className="download_attachment"
                            onClick={() => downloadF(file)}
                          >
                            cкачать
                          </span>
                        </div>
                      ))
                    : null}

                  <div style={{ padding: "20px 0" }}>
                    Выберите исполнителя:
                    <ToggleButtonGroup
                      className="task__modal_executor_group"
                      value={role}
                      exclusive
                      onChange={handleRoleChange}
                      size="small"
                      aria-label="role"
                    >
                      <ToggleButton value="metodist" aria-label="metodist">
                        Методист
                      </ToggleButton>
                      <ToggleButton
                        value="administrator"
                        aria-label="administrator"
                      >
                        Администратор
                      </ToggleButton>
                      <ToggleButton value="teacher" aria-label="teacher">
                        Учитель
                      </ToggleButton>
                      <ToggleButton value="sales" aria-label="sales">
                        Продажник
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <ReusableTextField
                    select
                    label="Исполнители"
                    value={taskValues.executor}
                    name="executor"
                    onChange={handleInputsChange}
                    options={findUsersByRole(users[role], managers)}
                  />
                </div>
                <RussianDatePicker
                  value={taskValues.date_due}
                  onChange={handleDateChange}
                  name="date_due"
                  inputFormat="YYYY-MM-DD"
                  label="Выберите дату"
                />
              </main>

              <div className="popup__footer">
                <div className="popup__subtitle" onClick={handleExpandComment}>
                  Добавить комментарий:
                </div>
                <div
                  className="wrapper__comments"
                  style={{ display: expandComment ? "flex" : "none" }}
                >
                  <div>
                    <Avatar
                      sx={{
                        width: 35,
                        height: 35,
                        fontSize: "1rem",
                        bgcolor: "#ac69e5",
                      }}
                    >
                      {`${tasksCreator.charAt(0).toUpperCase()}`}
                    </Avatar>
                  </div>
                  <div className="tasks_footer_btn">
                    <div style={{ width: "100%" }}>
                      <ReusableTextField
                        text
                        multiline
                        rows={4}
                        label="Комментарий"
                        value={taskValues.new_comment}
                        name="new_comment"
                        onChange={handleInputsChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="wrapper__buttons">
                  <Button onClick={handleTaskSubmit}>Сохранить</Button>
                  <Button onClick={handleTaskCancel}>Отмена</Button>
                </div>
              </div>
            </div>
            <div className="popup__right">
              <div className="wrapper__aside">
                <div className="popup__subtitle">Автор:</div>
                <div className="wrapper__avatar">
                  <Avatar
                    sx={{
                      width: 25,
                      height: 25,
                      fontSize: "1rem",
                      bgcolor: "#ac69e5",
                    }}
                  >
                    {`${tasksCreator.charAt(0).toUpperCase() || ""}`}
                  </Avatar>
                  <div className="popup__subtitle_name">
                    {tasksCreator || ""}
                  </div>
                </div>
              </div>
              <div className="wrapper__aside">
                <div className="popup__subtitle">Исполнитель:</div>
                <div className="wrapper__avatar">
                  <Avatar
                    sx={{
                      width: 25,
                      height: 25,
                      fontSize: "1rem",
                      bgcolor: "#ac69e5",
                    }}
                  >
                    {`${
                      findLabelByID(taskValues.executor, managers)
                        .charAt(0)
                        .toUpperCase() || ""
                    }`}
                  </Avatar>
                  <div className="popup__subtitle_name">
                    {findLabelByID(taskValues.executor, managers) || ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SimpleConfirmModal
        isOpen={isNotificationOpen}
        onClose={closeNotificationModal}
        onConfirm={hanleConfirm}
        content={` Задача ${taskValues?.title} отправлена исполнителю ${
          findLabelByID(taskValues.executor, managers) || ""
        } от ${taskValues?.date_due || ""} `}
      />
    </>
  );
};

export default TaskModalCreate;
