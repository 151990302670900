/* eslint-disable consistent-return */
/* eslint-disable no-else-return */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-destructuring */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import clsx from "clsx";

import React, { useState } from "react";

import { connect, useDispatch } from "react-redux";
import DataGrid from "../../components/DataGrid/DataGrid";
import {
  addNewUser,
  enableUser,
  fetchAccess,
  removeUser,
  updateAccess,
} from "../../redux/actions/accessActions";
import {
  createMultipleSelectColumn,
  createTextColumn,
} from "../../utils/helpers/columnsCreators";
import { setPageTitle } from "../../redux/actions/globalActions";
import ModalTransfer from "./ModalTransfer";
import { accessApi } from "../../utils/api";
import ModalActivate from "./ModalActivate";

const mapStateToProps = (state) => ({
  globalState: state.globalState,
  data: state.access.payload,
  loading: state.access.loading,
  entitiesTypes: state.entitiesTypes,
  permissions: {
    add: false,
    modify: true,
    remove: false,
    view: false,
  },
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(fetchAccess()),
  updateData: (obj) => {
    dispatch(updateAccess(obj));
  },
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },

  // disabledData: (obj) =>
  // 	dispatch(removeUser(obj), console.log("send to server", obj)),
});

const AccessPage = ({ title, data, entitiesTypes, setTitle, ...props }) => {
  React.useEffect(() => {
    setTitle(title);
  }, []);

  const initiaReverseValues = {
    access_id: "Роль",
    email: "Email",
    short_name: "ФИО",
    location: "Локация",
  };

  const initialDisabledUser = {
    last_activity: "",
    access_id: [],
    email: "",
    short_name: "",
    location: [],
    status: "",
  };

  const initialUserDebts = {
    appointments: "",
    leads: "",
    tasks: "",
  };

  const [errorMessage, setErrorMessage] = useState("");
  const [nameValue, setNewValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [locationValue, setLocationValue] = useState([]);
  const [accessValue, setAccessValue] = useState([]);
  const [isButtonShow, setIsButtonShow] = useState(false);
  const [infoAboutUser, setInfoAboutUser] = useState(initialDisabledUser);
  const [userDebts, setUserDebts] = useState(initialUserDebts);
  const [isActivateModalOpen, setActivateModalOpen] = useState(false);
  const handleOpenCancelModal = () => {
    setActivateModalOpen(true);
  };

  const [isTransferModalOpen, setTransferModalOpen] = useState(false);

  const handleOpenTransferModal = () => {
    setTransferModalOpen(true);
  };

  const handleCloseTransferModal = () => {
    setTransferModalOpen(false);
  };

  const dispatch = useDispatch();
  const users = entitiesTypes.user;
  const active_users = entitiesTypes.active_users;

  const editAccessNames = (source) => {
    const labelsToRemove = ["viewer", "parent", "child"];
    const newArray = source.filter((obj) => {
      for (let i = 0; i < labelsToRemove.length; i++) {
        if (obj.label === labelsToRemove[i]) {
          return false;
        }
      }
      return true;
    });

    return newArray;
  };

  const columns = [
    createTextColumn("id", "ФИО", users, false, { flex: 1 }),

    {
      field: "email",
      headerName: "E-mail",
      flex: 1,
      editable: false,
    },
    createMultipleSelectColumn(
      "location",
      "Локация",
      entitiesTypes.location,
      true,
      { flex: 1 }
    ),
    createMultipleSelectColumn(
      "access_id",
      "Роль",
      entitiesTypes.access_name,
      true,
      { flex: 1 }
    ),
    {
      field: "last_activity",
      headerName: "Последняя дата входа",
      flex: 1,
      editable: false,
    },
    {
      field: "status",
      headerName: "Статус",
      flex: 1,
      editable: false,
      headerClassName: "super-app-theme--header",
      // eslint-disable-next-line consistent-return
      cellClassName: (params) => {
        const userActivity = params.row.status;
        if (userActivity)
          return clsx("super-app", {
            positive: userActivity === "active",
            negative: userActivity !== "active",
          });
      },
    },
  ];

  const handleLocationChange = (event) => {
    setLocationValue(event.target.value);
  };

  const handleAccessChange = (event) => {
    setAccessValue(event.target.value);
  };

  const handleNameChange = (event) => {
    setNewValue(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmailValue(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const obj = {
      user: {
        access_id: accessValue,
        email: emailValue,
        short_name: nameValue,
        location: locationValue,
      },
    };

    const result = Object.keys(obj.user).filter(
      (item) => obj.user[item] === "" || obj.user[item].length === 0
    );
    if (result.length !== 0) {
      const fields = result.map((item) => initiaReverseValues[item]);
      setErrorMessage(`необходимо заполнить все поля: ${fields} `);
      return;
    }
    dispatch(addNewUser(obj));
    setErrorMessage("");
  };

  const handleRowDetails = (row) => {
    setIsButtonShow(true);
    setInfoAboutUser(row.row);
  };

  const handleButtonClick = () => {
    accessApi.checkDebts(infoAboutUser.id).then((res) => {
      setUserDebts((prevState) => ({ ...prevState, ...res.data }));
      handleOpenTransferModal();
    });
  };

  const disableUser = (inputs) => {
    const disabledUserObj = {
      user: infoAboutUser.id,
      transfer_to: { ...inputs },
    };
    dispatch(removeUser(disabledUserObj));
  };

  const handleCloseActivateModal = () => {
    setActivateModalOpen(false);
  };
  const activateUser = () => {
    dispatch(enableUser(infoAboutUser.id));
  };

  const handleActivateUser = () => {
    handleOpenCancelModal();
  };

  return (
    <div className="content-component__wrapper">
      <Typography variant="h4" component="h4" className="page__title">
        {title}
      </Typography>
      <div>
        <form onSubmit={handleSubmit}>
          <TextField
            id="outlined-basic"
            label="ФИО"
            variant="outlined"
            size="small"
            sx={{ m: 1, minWidth: 100 }}
            value={nameValue}
            onChange={handleNameChange}
          />
          <TextField
            id="outlined-basic"
            label="E-mail"
            variant="outlined"
            size="small"
            sx={{ m: 1, minWidth: 90 }}
            value={emailValue}
            onChange={handleEmailChange}
          />

          <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id="demo-simple-select-label">
              Выберите локацию
            </InputLabel>
            <Select
              sx={{ m: 1, minWidth: 200 }}
              labelId="demo-simple-select-label"
              multiple
              size="small"
              value={locationValue}
              onChange={handleLocationChange}
            >
              {entitiesTypes.location &&
                entitiesTypes.location.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id="demo-simple-select-label">Выберите роль</InputLabel>
            <Select
              sx={{ m: 1, minWidth: 200 }}
              labelId="demo-simple-select-label"
              multiple
              size="small"
              value={accessValue}
              onChange={handleAccessChange}
            >
              {entitiesTypes.access_name &&
                editAccessNames(entitiesTypes.access_name).map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            type="submit"
            size="small"
            sx={{
              margin: "9px",
              height: "37px",
            }}
          >
            Создать
          </Button>
        </form>
        <Box
          sx={{
            margin: "0 30px",
            color: "red",
            height: "20px",
            display: errorMessage ? "block" : "none",
          }}
        >
          {errorMessage}
        </Box>
      </div>
      {isButtonShow && infoAboutUser.status === "active" && (
        <>
          <Button
            variant="contained"
            type="button"
            onClick={handleButtonClick}
            size="small"
            sx={{
              margin: "9px",
              height: "37px",
            }}
          >
            Деактивировать пользователя
          </Button>
          <ModalTransfer
            open={isTransferModalOpen}
            handleClose={handleCloseTransferModal}
            disableUser={disableUser}
            userDebts={userDebts}
            users={users}
            active_users={active_users}
          />
        </>
      )}{" "}
      {isButtonShow && infoAboutUser.status === "disabled" && (
        <>
          <Button
            variant="contained"
            type="button"
            onClick={handleActivateUser}
            size="small"
            sx={{
              margin: "9px",
              height: "37px",
            }}
          >
            Активировать пользователя
          </Button>
          <ModalActivate
            open={isActivateModalOpen}
            handleClose={handleCloseActivateModal}
            activateConfirmed={activateUser}
          />
        </>
      )}
      <Box
        sx={{
          "& .super-app-theme--cell": {
            backgroundColor: "rgba(224, 183, 60, 0.55)",
            color: "#1a3e72",
            fontWeight: "600",
            border: "1px solid #000000",
          },
          "& .super-app.negative": {
            backgroundColor: "#fa8e64",
            color: "#1a3e72",
            fontWeight: "600",
            border: "1px solid #000000",
          },
          "& .super-app.positive": {
            backgroundColor: "rgba(140, 255, 111, 0.747)",
            color: "#1a3e72",
            fontWeight: "600",
            border: "1px solid #000000",
          },
        }}
      >
        <DataGrid
          sx={{ padding: "0 30px 20px 30px" }}
          onCellClick={(event, row) => handleRowDetails(event, row)}
          columns={columns}
          dataGridName="access"
          entityName="access"
          getRowId={(row) => `${row.id}`}
          {...props}
          data={data}
        />
      </Box>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessPage);
